import Script from "next/script";
import { ActiveMedia, playerActions } from "app/store/slices/player";
import { useAppDispatch } from "app/store/store";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useGetPillowAdvertisingTargetspot, useGetPillowBillingStripeV2Price } from "services-hooks/hooks";
import { useFeaturesContext } from "./features.context";
import { PriceData } from "services-hooks/types";
import { useNetworkState } from "react-use";

export const LOCAL_STORAGE_COUNTRY_KEY = "countryCode";
export const LOCAL_STORAGE_LOCALE_KEY = "locale";
export const VLYBY_TIMEOUT = 3 * 60000;
export const VLYBY_AD_ERROR_TIMEOUT = 12000;

interface PlayerContextValue {
  isPlaying: boolean;
  audioDots: { playerConstructor: null | any };
  isMediaLoading: boolean;
  duration: number | null;
  playerEnabled: boolean;
  setPlayerEnabled: (enabled: boolean) => void;
  httpMode: boolean;
  setHttpMode: (enabled: boolean) => void;
  fullscreenPlayerInitialyOpen: boolean;
  setFullscreenPlayerInitialyOpen: (enabled: boolean) => void;
  setIsPlaying: (enabled: boolean) => void;
  setIsMediaLoading: (enabled: boolean) => void;
  setDuration: (duration: number | null) => void;
  globalAdCloseTime: number;
  setGlobalAdCloseTime: (timestamp: number) => void;
  checkGlobalTimer: () => boolean;
  refetchMetadataInterval: number;
  priceData: PriceData;
}

const PlayerContext = createContext<PlayerContextValue | null>(null);

export const PlayerContextProvider: React.FC = ({ children }) => {
  const [fullscreenPlayerInitialyOpen, setFullscreenPlayerInitialyOpen] =
    useState(false);
  const [playerEnabled, setPlayerEnabled] = useState(false);
  const [httpMode, setHttpMode] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMediaLoading, setIsMediaLoading] = useState(false);
  const [duration, setDuration] = useState<number | null>(null);
  const dispatch = useAppDispatch();
  const { data: config } = useGetPillowAdvertisingTargetspot({
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    retry: 1,
  });

  const [globalAdCloseTime, setGlobalAdCloseTime] = useState(0);
  const checkGlobalTimer = () => {
    return Date.now() - globalAdCloseTime >= VLYBY_TIMEOUT;
  };

  useEffect(() => {
    dispatch(playerActions.setTargetspoConfig(config?.data || null));
  }, [config?.data]);
  const { audioDotsEnabled } = useFeaturesContext();
  const [audioDots, setAudioDots] = useState<{
    playerConstructor: any | null;
  }>({ playerConstructor: null });

  const defaultPrice: PriceData = {
    amount: "4.99",
    currency: "EUR",
    currency_symbol: "€",
  };
  const { data: priceDataRaw } = useGetPillowBillingStripeV2Price();
  const priceData = useMemo(() => {
    if (!(priceDataRaw?.data as any)?.data) {
      return defaultPrice;
    }
    const newPrice: PriceData = (priceDataRaw?.data as any)?.data;

    return {
      amount: newPrice.amount || defaultPrice.amount,
      currency: newPrice.currency || defaultPrice.currency,
      currency_symbol: newPrice.currency_symbol || defaultPrice.currency_symbol,
    };
  }, [priceDataRaw?.data]);

  const networkState = useNetworkState();
  const refetchMetadataInterval = useMemo(() => {
    if (networkState.effectiveType) {
      if (["2g", "slow-2g"].includes(networkState.effectiveType)) {
        return 15000;
      }
      if (["3g"].includes(networkState.effectiveType)) {
        return 10000;
      }
    }
    return 5000;
  }, [networkState]);

  return (
    <PlayerContext.Provider
      value={{
        audioDots,
        fullscreenPlayerInitialyOpen,
        setFullscreenPlayerInitialyOpen,
        playerEnabled,
        isPlaying,
        duration,
        setDuration,
        setIsPlaying,
        setIsMediaLoading,
        isMediaLoading,
        setPlayerEnabled,
        globalAdCloseTime,
        setGlobalAdCloseTime,
        checkGlobalTimer,
        httpMode,
        setHttpMode,
        refetchMetadataInterval,
        priceData
      }}
    >
      {/* AUDIODOTS ARE DISABLED  */}
      {audioDotsEnabled && (
        <Script
          id="aniplayer"
          src={`https://player.aniview.com/script/6.1/aniview.js?v=1&type=s&pid=619cb5b8553c9019897ec945`} //${audioDotsPublisherId}
          onLoad={() => {
            setAudioDots({ playerConstructor: (window as any).avPlayer });
          }}
        />
      )}
      {children}
    </PlayerContext.Provider>
  );
};

export const usePlayerContext = (): PlayerContextValue => {
  const value = useContext(PlayerContext);
  if (value === null)
    throw new Error("Make sure that you use your hook inside of PlayerContext");
  return value;
};
